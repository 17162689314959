import { Link, useLocation, useParams } from "react-router-dom";
import { NavBar, Sizes } from "@sede-x/shell-ds-react-framework";

const ExchangeDetailsNavbar = () => {

  const { pathname } = useLocation();
  const { exchangeName, exchangeId } = useParams();

  const NavbarItems = exchangeName !== 'limejump' ? [
    { label: `Mapping Groups`, key: "1-1", path: `/exchange-details/${exchangeName}/${exchangeId}` },
    { label: "Exchange Information", key: "1-2", path: `/exchange-info/${exchangeName}/${exchangeId}` }
  ] : [
    { label: `Mapping Groups`, key: "1-1", path: `/exchange-details/${exchangeName}/${exchangeId}` }
  ];

  const renderItems = ({ path, children }: {path: string, children: React.ReactNode}) => {
    const fontWeight = path === pathname ? "bold" : "normal";
    return <Link to={path} style={{ fontWeight: fontWeight }}>{children}</Link>
  };

  return (
    <>
      <NavBar
        style={{ position: 'relative', height: '72px' }}
        items={NavbarItems}
        size={Sizes.Large}
        itemRender={renderItems}
        selectedPath={pathname}
        pectenArea={"none"}
      />
    </>
  );
};

export default ExchangeDetailsNavbar;

