import { useParams } from "react-router-dom";
import { useAuth } from "oidc-react";
import isUserAdmin from "../../utils/isUserAdmin";
import ExchangeInfoDescription from "./components/ExchangeInfoDescription";
import ExchangeDetailsHeader from "../../components/ExchangeDetailsHeader";
import { useState } from "react";

const ExchangeInfo = () => {

    const { exchangeName, exchangeId } = useParams();
    debugger;
    const auth = useAuth();
    const isAdmin = isUserAdmin(auth.userData);

    const [isAlertHidden, setAlertHidden] = useState<{ hidden: boolean; message: string; alertType: string; }>({ hidden: true, message: "", alertType: "information" });

    return (
        <div style={{ position: "relative", backgroundColor: "white" }}>
        <ExchangeDetailsHeader isAlertHidden={isAlertHidden} setAlertHidden={setAlertHidden} />

        <ExchangeInfoDescription exchange={exchangeName} />

      </div>
        
    );
};

export default ExchangeInfo;
