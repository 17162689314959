import { BaseTable } from "@sede-x/shell-ds-react-framework";


const epexSpotM7Columns = [
    {
      "header": "Product",
      "accessorKey": "Product"
    },
    {
      "header": "Market Area",
      "accessorKey": "Market Area"
    },
    {
      "header": "Description",
      "accessorKey": "Description"
    },
    {
      "header": "Timezone",
      "accessorKey": "Timezone"
    },
    {
      "header": "Trading Procedure",
      "accessorKey": "Trading Procedure"
    },
    {
      "header": "Trading Days",
      "accessorKey": "Trading Days"
    },
    {
      "header": "EPEX Trading System",
      "accessorKey": "EPEX Trading System"
    }
  ];

const epexSpotM7Data = [
    {
      "Product": "GB_Hour_Power",
      "Market Area": "10YGB----------A",
      "Description": "Hourly Intraday GB Continuous Power",
      "Timezone": "BST",
      "Trading Procedure": "Continuous",
      "Trading Days": "Year-round",
      "EPEX Trading System": "M7"
    },
    {
      "Product": "GB_Half_Hour_Power",
      "Market Area": "10YGB----------A",
      "Description": "Half-Hourly Intraday GB Continuous Power",
      "Timezone": "BST",
      "Trading Procedure": "Continuous",
      "Trading Days": "Year-round",
      "EPEX Trading System": "M7"
    },
    {
      "Product": "GB_2_Hour_Power",
      "Market Area": "10YGB----------A",
      "Description": "GB 2 Hour Continuous Power Block",
      "Timezone": "BST",
      "Trading Procedure": "Continuous",
      "Trading Days": "Year-round",
      "EPEX Trading System": "M7"
    },
    {
      "Product": "GB_3_Plus_4",
      "Market Area": "10YGB----------A",
      "Description": "GB Blocks 3 & 4 covering hours 7 to 15",
      "Timezone": "BST",
      "Trading Procedure": "Continuous",
      "Trading Days": "Year-round",
      "EPEX Trading System": "M7"
    },
    {
      "Product": "GB_4_Hour_Power",
      "Market Area": "10YGB----------A",
      "Description": "GB 4 Hour Continuous Power Block",
      "Timezone": "BST",
      "Trading Procedure": "Continuous",
      "Trading Days": "Year-round",
      "EPEX Trading System": "M7"
    },
    {
      "Product": "GB_Baseload",
      "Market Area": "10YGB----------A",
      "Description": "GB Block baseload covering hours 1 to 24",
      "Timezone": "BST",
      "Trading Procedure": "Continuous",
      "Trading Days": "Year-round",
      "EPEX Trading System": "M7"
    },
    {
      "Product": "GB_Extended_Peak",
      "Market Area": "10YGB----------A",
      "Description": "GB Block extended peakload covering hours 7 to 23",
      "Timezone": "BST",
      "Trading Procedure": "Continuous",
      "Trading Days": "Year-round",
      "EPEX Trading System": "M7"
    },
    {
      "Product": "GB_Overnight",
      "Market Area": "10YGB----------A",
      "Description": "GB Block overnight covering hours 23 to 7",
      "Timezone": "BST",
      "Trading Procedure": "Continuous",
      "Trading Days": "Year-round",
      "EPEX Trading System": "M7"
    },
    {
      "Product": "GB_Peakload",
      "Market Area": "10YGB----------A",
      "Description": "GB Block peakload covering hours 7 to 19",
      "Timezone": "BST",
      "Trading Procedure": "Continuous",
      "Trading Days": "Year-round",
      "EPEX Trading System": "M7"
    },
    {
      "Product": "Intraday_Hour_Power",
      "Market Area": "10YCB-BE-------T",
      "Description": "Hourly Intraday BE Continuous Power",
      "Timezone": "CET",
      "Trading Procedure": "Continuous",
      "Trading Days": "Year-round",
      "EPEX Trading System": "M7"
    },
    {
      "Product": "Intraday_Hour_Power",
      "Market Area": "10YCH-SWISS1---B",
      "Description": "Hourly Intraday CH Continuous Power",
      "Timezone": "CET",
      "Trading Procedure": "Continuous",
      "Trading Days": "Year-round",
      "EPEX Trading System": "M7"
    },
    {
      "Product": "Intraday_Hour_Power",
      "Market Area": "10Y1001A1001A82H",
      "Description": "",
      "Timezone": "CET",
      "Trading Procedure": "Continuous",
      "Trading Days": "Year-round",
      "EPEX Trading System": "M7"
    },
    {
      "Product": "Intraday_Hour_Power",
      "Market Area": "10YFR-RTE-TEST-W",
      "Description": "Hourly Intraday FR Continuous Power",
      "Timezone": "CET",
      "Trading Procedure": "Continuous",
      "Trading Days": "Year-round",
      "EPEX Trading System": "M7"
    },
    {
      "Product": "Intraday_Hour_Power",
      "Market Area": "10YCB-NL-------V",
      "Description": "Hourly Intraday NL Continuous Power",
      "Timezone": "CET",
      "Trading Procedure": "Continuous",
      "Trading Days": "Year-round",
      "EPEX Trading System": "M7"
    },
    {
      "Product": "Intraday_Half_Hour_Power",
      "Market Area": "10YCB-BE-------T",
      "Description": "Half-Hourly Intraday BE Continuous Power",
      "Timezone": "CET",
      "Trading Procedure": "Continuous",
      "Trading Days": "Year-round",
      "EPEX Trading System": "M7"
    },
    {
      "Product": "Intraday_Half_Hour_Power",
      "Market Area": "10YCH-SWISS1---B",
      "Description": "Half-Hourly Intraday CH Continuous Power",
      "Timezone": "CET",
      "Trading Procedure": "Continuous",
      "Trading Days": "Year-round",
      "EPEX Trading System": "M7"
    },
    {
      "Product": "Intraday_Half_Hour_Power",
      "Market Area": "10Y1001A1001A82H",
      "Description": "Half-Hourly Intraday DE Continuous Power",
      "Timezone": "CET",
      "Trading Procedure": "Continuous",
      "Trading Days": "Year-round",
      "EPEX Trading System": "M7"
    },
    {
      "Product": "Intraday_Half_Hour_Power",
      "Market Area": "10YFR-RTE-TEST-W",
      "Description": "Half-Hourly Intraday FR Continuous Power",
      "Timezone": "CET",
      "Trading Procedure": "Continuous",
      "Trading Days": "Year-round",
      "EPEX Trading System": "M7"
    },
    {
      "Product": "Intraday_Half_Hour_Power",
      "Market Area": "10YCB-NL-------V",
      "Description": "Half-Hourly Intraday NL Continuous Power",
      "Timezone": "CET",
      "Trading Procedure": "Continuous",
      "Trading Days": "Year-round",
      "EPEX Trading System": "M7"
    },
    {
      "Product": "Intraday_Quarter_Hour_Power",
      "Market Area": "10YCB-BE-------T",
      "Description": "Quarter Hourly Intraday BE Continuous Power",
      "Timezone": "CET",
      "Trading Procedure": "Continuous",
      "Trading Days": "Year-round",
      "EPEX Trading System": "M7"
    },
    {
      "Product": "Intraday_Quarter_Hour_Power",
      "Market Area": "10YCH-SWISS1---B",
      "Description": "Quarter Hourly Intraday CH Continuous Power",
      "Timezone": "CET",
      "Trading Procedure": "Continuous",
      "Trading Days": "Year-round",
      "EPEX Trading System": "M7"
    },
    {
      "Product": "Intraday_Quarter_Hour_Power",
      "Market Area": "10Y1001A1001A82H",
      "Description": "Quarter Hourly Intraday DE Continuous Power",
      "Timezone": "CET",
      "Trading Procedure": "Continuous",
      "Trading Days": "Year-round",
      "EPEX Trading System": "M7"
    },
    {
      "Product": "Intraday_Quarter_Hour_Power",
      "Market Area": "10YCB-NL-------V",
      "Description": "Quarter Hourly Intraday NL Continuous Power",
      "Timezone": "CET",
      "Trading Procedure": "Continuous",
      "Trading Days": "Year-round",
      "EPEX Trading System": "M7"
    },
    {
      "Product": "XBID_Hour_Power",
      "Market Area": "10YCB-BE-------T",
      "Description": "XBID Hourly BE Continuous Power",
      "Timezone": "CET",
      "Trading Procedure": "Continuous",
      "Trading Days": "Year-round",
      "EPEX Trading System": "M7"
    },
    {
      "Product": "XBID_Hour_Power",
      "Market Area": "10Y1001A1001A82H",
      "Description": "XBID Hourly DE Continuous Power",
      "Timezone": "CET",
      "Trading Procedure": "Continuous",
      "Trading Days": "Year-round",
      "EPEX Trading System": "M7"
    },
    {
      "Product": "XBID_Hour_Power",
      "Market Area": "10YFR-RTE-TEST-W",
      "Description": "XBID Hourly FR Continuous Power",
      "Timezone": "CET",
      "Trading Procedure": "Continuous",
      "Trading Days": "Year-round",
      "EPEX Trading System": "M7"
    },
    {
      "Product": "XBID_Hour_Power",
      "Market Area": "10YCB-NL-------V",
      "Description": "XBID Hourly NL Continuous Power",
      "Timezone": "CET",
      "Trading Procedure": "Continuous",
      "Trading Days": "Year-round",
      "EPEX Trading System": "M7"
    },
    {
      "Product": "XBID_Half_Hour_Power",
      "Market Area": "10YCB-BE-------T",
      "Description": "XBID Half-Hourly BE Continuous Power",
      "Timezone": "CET",
      "Trading Procedure": "Continuous",
      "Trading Days": "Year-round",
      "EPEX Trading System": "M7"
    },
    {
      "Product": "XBID_Half_Hour_Power",
      "Market Area": "10YFR-RTE-TEST-W",
      "Description": "XBID Half-Hourly FR Continuous Power",
      "Timezone": "CET",
      "Trading Procedure": "Continuous",
      "Trading Days": "Year-round",
      "EPEX Trading System": "M7"
    },
    {
      "Product": "XBID_Half_Hour_Power",
      "Market Area": "10Y1001A1001A82H",
      "Description": "XBID Half-Hourly DE Continuous Power",
      "Timezone": "CET",
      "Trading Procedure": "Continuous",
      "Trading Days": "Year-round",
      "EPEX Trading System": "M7"
    },
    {
      "Product": "XBID_Half_Hour_Power",
      "Market Area": "10YCB-NL-------V",
      "Description": "XBID Half-Hourly NL Continuous Power",
      "Timezone": "CET",
      "Trading Procedure": "Continuous",
      "Trading Days": "Year-round",
      "EPEX Trading System": "M7"
    },
    {
      "Product": "XBID_Quarter_Hour_Power",
      "Market Area": "10YCB-BE-------T",
      "Description": "XBID Quarter Hourly BE Continuous Power",
      "Timezone": "CET",
      "Trading Procedure": "Continuous",
      "Trading Days": "Year-round",
      "EPEX Trading System": "M7"
    },
    {
      "Product": "XBID_Quarter_Hour_Power",
      "Market Area": "10Y1001A1001A82H",
      "Description": "XBID Quarter Hourly DE Continuous Power",
      "Timezone": "CET",
      "Trading Procedure": "Continuous",
      "Trading Days": "Year-round",
      "EPEX Trading System": "M7"
    },
    {
      "Product": "XBID_Quarter_Hour_Power",
      "Market Area": "10YCB-NL-------V",
      "Description": "XBID Quarter Hourly NL Continuous Power",
      "Timezone": "CET",
      "Trading Procedure": "Continuous",
      "Trading Days": "Year-round",
      "EPEX Trading System": "M7"
    }
  ];

const epexM7Data = [
    {
        product: 'CMEGB_Hour_Power', marketArea: '10YGB----------A', description: 'Hourly Intraday GB Continuous Power', timezone: 'BST', tradingProcedure: 'Continuous', tradingDays: 'Year-round', epexTradingSystem: 'M7'
    },
    {
        product: 'CMEGB_Half_Hour_Power', marketArea: '10YGB----------A', description: 'Half-Hourly Intraday GB Continuous Power', timezone: 'BST', tradingProcedure: 'Continuous', tradingDays: 'Year-round', epexTradingSystem: 'M7'
    },
    {
        product: 'CMEGB_2_Hour_Power', marketArea: '10YGB----------A', description: 'GB 2 Hour Continuous Power Block', timezone: 'BST', tradingProcedure: 'Continuous', tradingDays: 'Year-round', epexTradingSystem: 'M7'
    },
    {
        product: 'CMEGB_3_Plus_4', marketArea: '10YGB----------A', description: 'GB Blocks 3 & 4 covering hours 7 to 15', timezone: 'BST', tradingProcedure: 'Continuous', tradingDays: 'Year-round', epexTradingSystem: 'M7'
    },
    {
        product: 'CMEGB_4_Hour_Power', marketArea: '10YGB----------A', description: 'GB 4 Hour Continuous Power Block', timezone: 'BST', tradingProcedure: 'Continuous', tradingDays: 'Year-round', epexTradingSystem: 'M7'
    },
    {
        product: 'CMEGB_Baseload', marketArea: '10YGB----------A', description: 'GB Block baseload covering hours 1 to 24', timezone: 'BST', tradingProcedure: 'Continuous', tradingDays: 'Year-round', epexTradingSystem: 'M7'
    },
    {
        product: 'CMEGB_Extended_Peak', marketArea: '10YGB----------A', description: 'GB Block extended peakload covering hours 7 to 23', timezone: 'BST', tradingProcedure: 'Continuous', tradingDays: 'Year-round', epexTradingSystem: 'M7'
    },
    {
        product: 'CMEGB_Overnight', marketArea: '10YGB----------A', description: 'GB Block overnight covering hours 23 to 7', timezone: 'BST', tradingProcedure: 'Continuous', tradingDays: 'Year-round', epexTradingSystem: 'M7'
    },
    {
        product: 'CMEGB_Peakload', marketArea: '10YGB----------A', description: 'GB Block peakload covering hours 7 to 19', timezone: 'BST', tradingProcedure: 'Continuous', tradingDays: 'Year-round', epexTradingSystem: 'M7'
    },
    {
        product: 'Intraday_Hour_Power', marketArea: '10YCB-BE-------T', description: 'Hourly Intraday BE Continuous Power', timezone: 'CET', tradingProcedure: 'Continuous', tradingDays: 'Year-round', epexTradingSystem: 'M7'
    },
    {
        product: 'Intraday_Hour_Power', marketArea: '10YCH-SWISS1---B', description: 'Hourly Intraday CH Continuous Power', timezone: 'CET', tradingProcedure: 'Continuous', tradingDays: 'Year-round', epexTradingSystem: 'M7'
    },
    {
        product: 'Intraday_Hour_Power', marketArea: '10Y1001A1001A82H', description: '', timezone: 'CET', tradingProcedure: 'Continuous', tradingDays: 'Year-round', epexTradingSystem: 'M7'
    },
    {
        product: 'IIntraday_Hour_Power', marketArea: '10YFR-RTE-TEST-W', description: 'Hourly Intraday FR Continuous Power', timezone: 'CET', tradingProcedure: 'Continuous', tradingDays: 'Year-round', epexTradingSystem: 'M7'
    }
];

const epexEtsData = [
    {
        "ID": "100001",
        "Market Area": "GB-NG",
        "Auction Name": "PWR-MRC-D+1",
        "Description": "Great Britain (GB) 60 min Day-Ahead Power Auction (09:20 UTC)",
        "Order Book Closure": "10:20:00",
        "Auction Results": "10:30:00",
        "Auction Timezone": "CET / CEST"
    },
    {
        "ID": "100002",
        "Market Area": "GB-NG-30min",
        "Auction Name": "PWR-30-call-GB-D+1",
        "Description": "Great Britain (GB) 30 min Day-Ahead Auction (15:30 UTC)",
        "Order Book Closure": "16:30:00",
        "Auction Results": "16:45:00",
        "Auction Timezone": "CET / CEST"
    },
    {
        "ID": "100003",
        "Market Area": "GB-NG-IDA1",
        "Auction Name": "PWR-SEM-GB-D+1",
        "Description": "Great Britain (GB) 30 min Intraday Coupled Power Auction with Ireland (17:30 UTC)",
        "Order Book Closure": "18:30:00",
        "Auction Results": "19:00:00",
        "Auction Timezone": "CET / CEST"
    },
    {
        "ID": "100004",
        "Market Area": "GB-NG-IDA2",
        "Auction Name": "PWR-SEM-GB-D",
        "Description": "Great Britain (GB) 30 min Intraday Coupled Power Auction with Ireland (08:00 UTC)",
        "Order Book Closure": "09:00:00",
        "Auction Results": "9:30:00",
        "Auction Timezone": "CET / CEST"
    },
    {
        "ID": "100005",
        "Market Area": "CH-SGD",
        "Auction Name": "PWR-CH-D+1",
        "Description": "Switzerland (CH) 60 min Day-Ahead Power Auction (10:00 UTC)",
        "Order Book Closure": "11:00:00",
        "Auction Results": "11:10:00",
        "Auction Timezone": "CET / CEST"
    },
    {
        "ID": "100006",
        "Market Area": "FR-RTE",
        "Auction Name": "PWR-MRC-D+1",
        "Description": "France (FR) 60 min Day-Ahead Power Auction (11:00 UTC)",
        "Order Book Closure": "12:00:00",
        "Auction Results": "12:57:00",
        "Auction Timezone": "CET / CEST"
    },
    {
        "ID": "100007",
        "Market Area": "BE-ELIA",
        "Auction Name": " PWR-MRC-D+1",
        "Description": "Belgium (BE) 60 min Day-Ahead Power Auction (11:00 UTC)",
        "Order Book Closure": "12:00:00",
        "Auction Results": "12:57:00",
        "Auction Timezone": "CET / CEST"
    },
    {
        "ID": "100008",
        "Market Area": "DE-AMP",
        "Auction Name": "PWR-MRC-D+1",
        "Description": "Germany (DE) 60 min Day-Ahead Power Auction (11:00 UTC)",
        "Order Book Closure": "12:00:00",
        "Auction Results": "12:57:00",
        "Auction Timezone": "CET / CEST"
    },
    {
        "ID": "100009",
        "Market Area": "NL-TENNETNL",
        "Auction Name": "PWR-MRC-D+1",
        "Description": "Netherlands (NL) 60 min Day-Ahead Power Auction (11:00 UTC)",
        "Order Book Closure": "12:00:00",
        "Auction Results": "12:57:00",
        "Auction Timezone": "CET / CEST"
    },
    {
        "ID": "100010",
        "Market Area": "NL-TENNETNL-15min",
        "Auction Name": "PWR-15-call-NL-D+1",
        "Description": "Netherlands (NL) 15 min Intraday Power Auction (14:00 UTC)",
        "Order Book Closure": "15:00:00",
        "Auction Results": "15:40:00",
        "Auction Timezone": "CET / CEST"
    }
];

const epexEtsColumns = [
    {
        header: 'ID',
        accessorKey: 'ID'
    },
    {
        header: 'Market Area',
        accessorKey: 'Market Area'
    },
    {
        header: 'Auction Name',
        accessorKey: 'Auction Name'
    },
    {
        header: 'Description',
        accessorKey: 'Description'
    },
    {
        header: 'Order Book Closure',
        accessorKey: 'Order Book Closure'
    },
    {
        header: 'Auction Results',
        accessorKey: 'Auction Results'
    },
    {
        header: 'Auction Timezone',
        accessorKey: 'Auction Timezone'
    }
];

const n2ExColumns = [
    {
        header: 'N2EX Product ID',
        accessorKey: 'N2EX Product ID'
    },
    {
        header: 'Description',
        accessorKey: 'Description'
    },
    {
        header: 'Area Code',
        accessorKey: 'Area Code'
    },
    {
        header: 'Order Book Closure',
        accessorKey: 'Order Book Closure'
    },
    {
        header: 'Auction Results',
        accessorKey: 'Auction Results'
    },
    {
        header: 'Auction Timezone',
        accessorKey: 'Auction Timezone'
    }
];

const n2ExData = [
    {
        "N2EX Product ID": "N2EX_DA",
        "Description": "UK 60 min N2EX Day Ahead Power Auction",
        "Area Code": "UK",
        "Order Book Closure": "10:50:00 AM",
        "Auction Results": "11:00:00 AM",
        "Auction Timezone": "CET / CEST"
    },
    {
        "N2EX Product ID": "GB_HH_1",
        "Description": "UK 30 min N2EX Day Ahead Power Auction",
        "Area Code": "UK",
        "Order Book Closure": "10:50:00 AM",
        "Auction Results": "15:35 CET",
        "Auction Timezone": "CET / CEST"
    }
];

const nextraData = [
    {
        "ID": "1000",
        "Market Area": "24",
        "Auction Name": "DE-AMPRION PWR-DE-D+1",
        "Description": "Germany (DE) 60 min Day-Ahead Power Auction",
        "Auction Schedule": "Daily",
        "Order Book Closure": "12:00:00",
        "Auction Results": "14:00:00",
        "Auction Timezone": "CET"
    },
    {
        "ID": "1000",
        "Market Area": "25",
        "Auction Name": "DE-AMPRION PWR-15-DE-D+1",
        "Description": "Germany (DE) 15 min Day-Ahead Power Auction",
        "Auction Schedule": "Daily",
        "Order Book Closure": "12:00:00",
        "Auction Results": "14:00:00",
        "Auction Timezone": "CET"
    }
];

const nextraColumns = [
    {
        "header": "ID",
        "accessorKey": "ID"
    },
    {
        "header": "Market Area",
        "accessorKey": "Market Area"
    },
    {
        "header": "Auction Name",
        "accessorKey": "Auction Name"
    },
    {
        "header": "Description",
        "accessorKey": "Description"
    },
    {
        "header": "Auction Schedule",
        "accessorKey": "Auction Schedule"
    },
    {
        "header": "Order Book Closure",
        "accessorKey": "Order Book Closure"
    },
    {
        "header": "Auction Results",
        "accessorKey": "Auction Results"
    },
    {
        "header": "Auction Timezone",
        "accessorKey": "Auction Timezone"
    }
];

const iceSlmtColumns = [
    {
        "header": "ICE Product ID",
        "accessorKey": "ICE Product ID"
    },
    {
        "header": "ICE Product Name",
        "accessorKey": "ICE Product Name"
    },
    {
        "header": "Group",
        "accessorKey": "Group"
    },
    {
        "header": "Instrument Type",
        "accessorKey": "Instrument Type"
    },
    {
        "header": "Is Spread",
        "accessorKey": "Is Spread"
    },
    {
        "header": "Strip in months",
        "accessorKey": "Strip in months"
    },
    {
        "header": "Market Type",
        "accessorKey": "Market Type"
    },
    {
        "header": "Trading Procedure",
        "accessorKey": "Trading Procedure"
    }
];

const iceSlmtData = [
    {
        "ICE Product ID": "10",
        "ICE Product Name": "Crude Futures - Brent 1st Line",
        "Group": "Crude Oil and Refined Products",
        "Instrument Type": "Futures",
        "Is Spread": "No",
        "Strip in months": "No",
        "Market Type": "2 (Oil)",
        "Trading Procedure": "Continuous"
    },
    {
        "ICE Product ID": "25041",
        "ICE Product Name": "LNG Freight Time Charter Futures - Spark25S Pacific",
        "Group": "Liquified Natural Gas",
        "Instrument Type": "Futures",
        "Is Spread": "No",
        "Strip in months": "No",
        "Market Type": "193 (LNG Freight)",
        "Trading Procedure": "Continuous"
    },
    {
        "ICE Product ID": "25040",
        "ICE Product Name": "LNG Freight Time Charter Futures - Spark30S Atlantic",
        "Group": "Liquified Natural Gas",
        "Instrument Type": "Futures",
        "Is Spread": "No",
        "Strip in months": "No",
        "Market Type": "193 (LNG Freight)",
        "Trading Procedure": "Continuous"
    },
    {
        "ICE Product ID": "22286",
        "ICE Product Name": "LNG Futures (balmo) - JKM",
        "Group": "Liquified Natural Gas",
        "Instrument Type": "Futures",
        "Is Spread": "No",
        "Strip in months": "No",
        "Market Type": "93 (Financial LNG)",
        "Trading Procedure": "Continuous"
    },
    {
        "ICE Product ID": "4172",
        "ICE Product Name": "LNG Futures – JKM",
        "Group": "Liquified Natural Gas",
        "Instrument Type": "Futures",
        "Is Spread": "Can be booked as spread as well as standalone.",
        "Strip in months": "No",
        "Market Type": "93 (Financial LNG)",
        "Trading Procedure": "Continuous"
    },
    {
        "ICE Product ID": "20150",
        "ICE Product Name": "LNG Futures – JKM spr",
        "Group": "Liquified Natural Gas",
        "Instrument Type": "Futures",
        "Is Spread": "Yes",
        "Strip in months": "No",
        "Market Type": "93 (Financial LNG)",
        "Trading Procedure": "Continuous"
    },
    {
        "ICE Product ID": "25729",
        "ICE Product Name": "LNG Futures – WIM",
        "Group": "Liquified Natural Gas",
        "Instrument Type": "Futures",
        "Is Spread": "No",
        "Strip in months": "No",
        "Market Type": "93 (Financial LNG)",
        "Trading Procedure": "Continuous"
    },
    {
        "ICE Product ID": "20481",
        "ICE Product Name": "Dutch TTF Natural Gas 1st Line Financial Futures (USD/MMBtu)",
        "Group": "Natural Gas",
        "Instrument Type": "Futures",
        "Is Spread": "Can be booked as spread as well as standalone.",
        "Strip in months": "No",
        "Market Type": "3 (IPE Natural gas futures)",
        "Trading Procedure": "Continuous"
    },
    {
        "ICE Product ID": "20482",
        "ICE Product Name": "Dutch TTF Natural Gas 1st Line Financial Futures (USD/MMBtu) Spr",
        "Group": "Natural Gas",
        "Instrument Type": "Futures",
        "Is Spread": "No",
        "Strip in months": "No",
        "Market Type": "3 (IPE Natural gas futures)",
        "Trading Procedure": "Continuous"
    },
    {
        "ICE Product ID": "4172",
        "ICE Product Name": "Opt_LNG Futures - JKM",
        "Group": "Liquified Natural Gas",
        "Instrument Type": "Options",
        "Is Spread": "No",
        "Strip in months": "Yes",
        "Market Type": "93 (Financial LNG)",
        "Trading Procedure": "Continuous"
    },
    {
        "ICE Product ID": "20481",
        "ICE Product Name": "Opt_Dutch TTF Natural Gas 1st Line Financial Futures (USD/MMBtu)",
        "Group": "Natural Gas",
        "Instrument Type": "Options",
        "Is Spread": "No",
        "Strip in months": "Yes",
        "Market Type": "3 (IPE Natural gas futures)",
        "Trading Procedure": "Continuous"
    }
];

const omieColumns = [
    {
        "header": "ID",
        "accessorKey": "ID"
    },
    {
        "header": "Market Area",
        "accessorKey": "Market Area"
    },
    {
        "header": "Auction Name",
        "accessorKey": "Auction Name"
    },
    {
        "header": "Description",
        "accessorKey": "Description"
    },
    {
        "header": "Trading System",
        "accessorKey": "Trading System"
    },
    {
        "header": "Auction Schedule",
        "accessorKey": "Auction Schedule"
    },
    {
        "header": "Auction Results",
        "accessorKey": "Auction Results"
    },
    {
        "header": "Auction Timezone",
        "accessorKey": "Auction Timezone"
    }
];

const omieData = [
    {
        "ID": "100031",
        "Market Area": "ES-OMIE",
        "Auction Name": "PWR-ES-D+1",
        "Description": "Spain (ES) 60 min Day-Ahead Power Auction",
        "Trading System": "SIOM",
        "Auction Schedule": "Daily",
        "Auction Results": "13:00 CET/CEST",
        "Auction Timezone": "CET/CEST"
    },
    {
        "ID": "100032",
        "Market Area": "ES-OMIE",
        "Auction Name": "PWR-ES-D",
        "Description": "Spain (ES) 60 min Intraday Power Auction",
        "Trading System": "SIOM",
        "Auction Schedule": "Daily",
        "Auction Results": "15:00, 22:00, 10:00 CET/CEST",
        "Auction Timezone": "CET/CEST"
    },
    {
        "ID": "100033",
        "Market Area": "ES-OMIE",
        "Auction Name": "PWR-ES-IDC",
        "Description": "Spain (ES) 60 min Intraday Continuous Power Trading",
        "Trading System": "LTS",
        "Auction Schedule": "Year round",
        "Auction Results": "NA",
        "Auction Timezone": "CET/CEST"
    }
];

const cmeData = [
    {
        "Product Code": "GEO",
        "CME Group Product Name": "CBL Global Emissions Offset Futures",
        "Trade Types": "Futures / Blocks (OTC Trades) / Calendar Spread / Calendar Strip",
        "Underlying Delivery": "Monthly",
        "Lot Size": "1000 Environmental Offsets"
    },
    {
        "Product Code": "NGO",
        "CME Group Product Name": "CBL Nature-Based Global Emissions Offset Futures",
        "Trade Types": "Futures / Blocks (OTC Trades) / Calendar Spread / Calendar Strip",
        "Underlying Delivery": "Monthly",
        "Lot Size": "1000 Environmental Offsets"
    },
    {
        "Product Code": "CGO",
        "CME Group Product Name": "CBL Core Global Emissions Offset (C-GEO) Futures",
        "Trade Types": "Futures / Blocks (OTC Trades) / Calendar Spread / Calendar Strip",
        "Underlying Delivery": "Monthly",
        "Lot Size": "1000 Environmental Offsets"
    },
    {
        "Product Code": "NGO/GEO",
        "CME Group Product Name": "CBL Nature-Based Global Emissions Offset Futures/CBL Global Emissions Offset Futures",
        "Trade Types": "Inter-Commodity Spread",
        "Underlying Delivery": "Monthly",
        "Lot Size": "1000 Environmental Offsets"
    },
    {
        "Product Code": "CGO/GEO",
        "CME Group Product Name": "CBL Core Global Emissions Offset (C-GEO) Futures/CBL Global Emissions Offset Futures",
        "Trade Types": "Inter-Commodity Spread",
        "Underlying Delivery": "Monthly",
        "Lot Size": "1000 Environmental Offsets"
    },
    {
        "Product Code": "CGO/NGO",
        "CME Group Product Name": "CBL Core Global Emissions Offset (C-GEO) Futures/CBL Nature-Based Global Emissions Offset Futures",
        "Trade Types": "Inter-Commodity Spread",
        "Underlying Delivery": "Monthly",
        "Lot Size": "1000 Environmental Offsets"
    }
];

const cmeColumns = [
    {
        "header": "Product Code",
        "accessorKey": "Product Code"
    },
    {
        "header": "CME Group Product Name",
        "accessorKey": "CME Group Product Name"
    },
    {
        "header": "Trade Types",
        "accessorKey": "Trade Types"
    },
    {
        "header": "Underlying Delivery",
        "accessorKey": "Underlying Delivery"
    },
    {
        "header": "Lot Size",
        "accessorKey": "Lot Size"
    }
];


const ExchangeInfoDescription = ({ exchange }: { exchange: string | undefined }) => {

    if (exchange === 'epexspot-m7') {
        return (

            <>
                <div style={{ position: "relative", backgroundColor: "white" }}>

                    <div>
                        <p style={{ marginLeft: "1em", fontWeight: "bold", fontSize: "18px" }}>{exchange?.toLocaleUpperCase()}</p>
                    </div>
                    <div style={{ marginLeft: "1em" }}>
                        <p>
                            EPEX SPOT offers both continuous and auction trading in twelve European countries. All continuous Intraday markets of EPEX SPOT run on the M7 trading system (Comtrader).
                        </p>
                        <p>
                            <b>Tradable contracts:</b> hourly contracts, half-hourly contracts and 15-minute contracts
                        </p>
                        <p>
                            Intraday Continuous orders for GB and European markets are placed in one of the following ways
                        </p>
                        <p>
                            <ul>
                                <li>Orders are placed in the Epexspot’s ComTrader client</li>
                                <li> Algo orders via Exxeta </li>
                                <li> Orders to close out intraday positions via Likron </li>
                            </ul>
                        </p>

                    </div>

                    <div style={{ paddingTop: "1em" }}>
                        <p style={{ marginLeft: "1em", fontWeight: "bold", fontSize: "18px" }}>Trade Products and Schedule</p>
                        <BaseTable data={epexSpotM7Data} columns={epexSpotM7Columns} />
                    </div>
                </div>
            </>
        );
    } else if (exchange === 'epexspot-ets') {
        return (

            <>
                <div style={{ position: "relative", backgroundColor: "white" }}>

                    <div>
                        <p style={{ marginLeft: "1em", fontWeight: "bold", fontSize: "18px" }}>{exchange?.toLocaleUpperCase()}</p>
                    </div>
                    <div style={{ marginLeft: "1em" }}>
                        <p>
                            The European Power Exchange EPEX SPOT operates organized short-term electricity markets with 24/7 market operation services.<br />
                            All Day-Ahead and Intraday auction markets of EPEXSPOT run on the EPEX Energy Trading System (ETS)
                        </p>
                        <p>
                            Day Ahead and Intraday Auction Orders for GB and European markets are placed in the ETS Client.
                        </p>
                    </div>


                    <div style={{ paddingTop: "1em" }}>
                        <p style={{ marginLeft: "1em", fontWeight: "bold", fontSize: "18px" }}>Trade Products and Schedule</p>
                        <BaseTable data={epexEtsData} columns={epexEtsColumns} />
                    </div>
                </div>
            </>
        );
    } else if (exchange === 'n2ex') {
        return (

            <>
                <div style={{ position: "relative", backgroundColor: "white" }}>

                    <div>
                        <p style={{ marginLeft: "1em", fontWeight: "bold", fontSize: "18px" }}>{exchange?.toLocaleUpperCase()}</p>
                    </div>
                    <div style={{ marginLeft: "1em" }}>
                        <p>
                            N2EX provides market and clearing services for the UK wholesale power market.<br /> It is a joint venture between Nord Pool Spot and Nasdaq OMX Commodities AS.
                        </p>
                        <p>
                            SEEL participates in N2EX Day Ahead Hourly and Half Hourly power auctions for GB market.
                        </p>
                    </div>


                    <div style={{ paddingTop: "1em" }}>
                        <p style={{ marginLeft: "1em", fontWeight: "bold", fontSize: "18px" }}>Trade Products and Schedule</p>
                        <BaseTable data={n2ExData} columns={n2ExColumns} />
                    </div>
                </div>
            </>
        );
    } else if (exchange === 'nextra') {
        return (

            <>
                <div style={{ position: "relative", backgroundColor: "white" }}>

                    <div>
                        <p style={{ marginLeft: "1em", fontWeight: "bold", fontSize: "18px" }}>{exchange?.toLocaleUpperCase()}</p>
                    </div>
                    <div style={{ marginLeft: "1em" }}>
                        <p>
                            Next Kraftwerke provides market access to major power exchanges in Europe (EPEXSPOT, EXAA etc) and is one of the largest European traders of power from renewable energies.
                        </p>
                        <p>
                            The NEXTRA portal provides Shell market access for short-term trading on the Day-Ahead and Intraday markets for Shell Amprion Germany.
                        </p>
                    </div>


                    <div style={{ paddingTop: "1em" }}>
                        <p style={{ marginLeft: "1em", fontWeight: "bold", fontSize: "18px" }}>Trade Products and Schedule</p>
                        <BaseTable data={nextraData} columns={nextraColumns} />
                    </div>
                </div>
            </>
        );
    } else if (exchange === 'omie') {
        return (

            <>
                <div style={{ position: "relative", backgroundColor: "white" }}>

                    <div>
                        <p style={{ marginLeft: "1em", fontWeight: "bold", fontSize: "18px" }}>{exchange?.toLocaleUpperCase()}</p>
                    </div>
                    <div style={{ marginLeft: "1em" }}>
                        <p>
                            OMIE (OMI-Polo Español S.A.) is the nominated electricity market operator for managing the Iberian Peninsula's Spain and Portugal electricity markets.
                        </p>
                        <p>
                            SEEL participates in Spain Day Ahead and Intraday Auction markets and Spain Intraday continuous electricity markets.
                        </p>
                        <p>
                            Day Ahead and Intraday Auction Orders for Spanish market are executed in the SIOM system
                        </p>
                        <p>
                            Intraday Continuous orders for Spanish market are executed in the LTS system.
                        </p>
                    </div>


                    <div style={{ paddingTop: "1em" }}>
                        <p style={{ marginLeft: "1em", fontWeight: "bold", fontSize: "18px" }}>Trade Products and Schedule</p>
                        <BaseTable data={omieData} columns={omieColumns} />
                    </div>
                </div>
            </>
        );
    } else if (exchange === 'cme') {
        return (

            <>
                <div style={{ position: "relative", backgroundColor: "white" }}>

                    <div>
                        <p style={{ marginLeft: "1em", fontWeight: "bold", fontSize: "18px" }}>{exchange?.toLocaleUpperCase()}</p>
                    </div>
                    <div style={{ marginLeft: "1em" }}>
                        <p>
                            CME (Chicago Mercantile Exchange) is a futures & options exchange, and its where the Shell Energy North America (SENA) Carbon Desk is the single interface for the Environmental Products Trading Business.
                        </p>
                        <p>
                            Shell's Environmental Products Trading (EPT) desk in the Americas use TCP to capture EPT Emission Offset trades executed on the CME exchange under the Shell Energy North America (US) L.P. legal entity.
                        </p>

                    </div>


                    <div style={{ paddingTop: "1em" }}>
                        <p style={{ marginLeft: "1em", fontWeight: "bold", fontSize: "18px" }}>Trade Products and Schedule</p>
                        <BaseTable data={cmeData} columns={cmeColumns} />
                    </div>
                </div>
            </>
        );
    } else if (exchange === 'exxeta') {
        return (

            <>
                <div style={{ position: "relative", backgroundColor: "white" }}>

                    <div>
                        <p style={{ marginLeft: "1em", fontWeight: "bold", fontSize: "18px" }}>{exchange?.toLocaleUpperCase()}</p>
                    </div>
                    <div style={{ marginLeft: "1em" }}>
                        <p>
                            Exxeta is an external SaaS solution where market orders are placed or generated as a result of systematic (Algo) trading. <br />
                            Within Exxeta, the Internal Market acts as a matching engine for the hosted strategies (Algo's).
                        </p>
                        <p>
                            The platform facilitates internal trades (covering algo-to-algo, algo-to-manual and manual-to-algo) to ensure no internal Shell-to-Shell trades is concluded on an external platform. <br />
                            When orders are fulfilled internally, an Internal Book Trade (IBT) is created.
                        </p>

                    </div>
                </div>
            </>
        );
    } else if (exchange === 'ice-slmt') {
        return (

            <>
                <div style={{ position: "relative", backgroundColor: "white" }}>

                    <div>
                        <p style={{ marginLeft: "1em", fontWeight: "bold", fontSize: "18px" }}>{exchange?.toLocaleUpperCase()}</p>
                    </div>
                    <div style={{ marginLeft: "1em" }}>
                        <p>
                            Shell LNG Management & Trading (SLMT) trade TTF/JKM Locational Spreads on ICE via WebICE and brokers via ICEBLOCK.
                        </p>
                        <p>
                            Trade Capture Platform (TCP) is used to process the JKM/TTF ICE Locational Spreads and other agreed ICE products from ICE platform into SE Endur. <br />
                            TCP facilitates a seamless transition away from the ICE gateway and optimizes trade integration processes for improved operational efficiency.
                        </p>

                    </div>


                    <div style={{ paddingTop: "1em" }}>
                        <p style={{ marginLeft: "1em", fontWeight: "bold", fontSize: "18px" }}>Trade Products and Schedule</p>
                        <BaseTable data={iceSlmtData} columns={iceSlmtColumns} />
                    </div>
                </div>
            </>
        );
    }
    else {
        return null;
    }

};

export default ExchangeInfoDescription;
